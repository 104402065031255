import React from 'react'
import Link from 'gatsby-link'
import Layout from '../components/Layouts/Default'

import Seo from '../components/Seo'

function Apps() {
  return (
    <Layout>
      <div className="contact basic">
        <Seo meta="apps" />
        <h1>Apps</h1>
        <p>
          {`I'll be releasing app versions of each of the Hiragana and Katakana games for anyone who doesn't want to play the games in a browser. The first app will be for our `}
          <Link to="/japanese/games/kana-bento/">{`Kana game - Kana Bento`}</Link>
          {`. I have a fair few other games in the works, and will release them first on the site, and then as apps.`}
        </p>
      </div>
    </Layout>
  )
}

export default Apps
